import React, { FC, useCallback, useState } from "react";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { Alert, Box, Stack, Typography, Link } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { normaliseUsername } from "~/components/auth/utils";
import PasswordResetForm from "~/components/auth/PasswordResetForm";
import FormField from "~/components/FormField";
import PasswordField from "~/components/PasswordField";
import LoadingButton from "~/components/LoadingButton";
import NavLink from "~/components/NavLink";
import { withPrettyLabels, emailOrPhoneSchema } from "~/utils/validation";
import { Helmet } from "react-helmet-async";

const loginSchema = withPrettyLabels(
  yup.object({
    emailAddressOrMobileNumber: emailOrPhoneSchema,
    password: yup.string().required(),
  })
);

type Props = {
  onSignupClick?: (e: React.MouseEvent) => void;
  noRedirect?: boolean;
};

interface LoginFormValues {
  emailAddressOrMobileNumber: string;
  password: string;
}
const LoginForm: FC<Props> = ({ onSignupClick, noRedirect = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [[challengeName, user], setChallengeName] = useState<
    [string, CognitoUser | undefined]
  >(["", undefined]);
  const methods = useForm<LoginFormValues>({
    resolver: yupResolver(loginSchema),
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const [formError, setFormError] = useState("");
  const inBookingFlow = location.state?.flow === "booking";

  const handleRedirect = useCallback(() => {
    if (location.state?.flow === "booking") {
      navigate("/booking/availability");
    } else if (
      location.state?.returnTo &&
      !["/login", "/signup"].includes(location.state?.returnTo)
    ) {
      navigate(location.state?.returnTo);
    } else if (!noRedirect) {
      navigate("/");
    }
  }, [noRedirect, location, navigate]);

  const onSubmit = async (data: LoginFormValues) => {
    try {
      setFormError("");

      const username = normaliseUsername(
        data.emailAddressOrMobileNumber
      ) as string;

      const res = await Auth.signIn(username, data.password);
      if (window.heap) window.heap.track("login-complete");

      if (res.challengeName) {
        setChallengeName([res.challengeName, res as CognitoUser]);
      } else {
        handleRedirect();
      }
    } catch (ex) {
      if ((ex as Error)?.name === "PasswordResetRequiredException") {
        navigate("/password-reset-required", {
          state: {
            emailAddressOrMobileNumber: data.emailAddressOrMobileNumber,
          },
        });
        return;
      }

      console.error("Login failed", ex);
      setFormError((ex as Error).message || "Login failed");
    }
  };

  if (challengeName === "NEW_PASSWORD_REQUIRED") {
    return (
      <PasswordResetForm
        user={user as CognitoUser}
        onSetPassword={handleRedirect}
      />
    );
  }

  return (
    <FormProvider {...methods}>
      <Helmet>
        <title>Log in to your account</title>
        <meta
          name="description"
          content="Sign in to your PeerChat account. View or manage your next chat, or book a session with a ReachOut peer worker."
        />
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={2}>
          {onSignupClick && (
            <Typography sx={{ mb: 4 }}>
              Don&apos;t have an account?{" "}
              <Link color="primary" href="#" onClick={onSignupClick}>
                Sign up
              </Link>
            </Typography>
          )}
          <FormField
            name="emailAddressOrMobileNumber"
            label="Email address or mobile number"
            required
          />

          <Box sx={{ position: "relative" }}>
            <PasswordField name="password" label="Password" required />
            <NavLink
              to="/forgot-password"
              sx={{ position: "absolute", top: -4, right: 0 }}
            >
              Forgot password?
            </NavLink>
          </Box>

          {formError && <Alert severity="error">{formError}</Alert>}

          <Stack direction={inBookingFlow ? ["column", "row"] : "row"}>
            <LoadingButton type="submit" loading={isSubmitting}>
              {inBookingFlow ? "Next: Book my session" : "Log in"}
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
